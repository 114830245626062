import React from 'react';
import moment from 'moment';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useLocation } from 'react-router-dom';

const ShowByDate = ({ items }) => {
    const location = useLocation();
    const fixedCategories = ['Today', 'Yesterday', 'Previous 7 Days', 'Previous 30 Days'];

    const getCategorizedItems = () => {
        const now = moment(); // Use current date instead of static date
        const startOfToday = now.clone().startOf('day');

        let categories = {
            'Today': [],
            'Yesterday': [],
            'Previous 7 Days': [],
            'Previous 30 Days': [],
        };

        const sortedItems = [...items].sort((a, b) =>
            moment(b.update_time).valueOf() - moment(a.update_time).valueOf()
        );

        sortedItems.forEach(item => {
            const itemMoment = moment(item.update_time);
            const dayDiff = startOfToday.diff(itemMoment, 'days');

            if (dayDiff === 0) {
                categories['Today'].push(item);
            }
            else if (dayDiff === 1) {
                categories['Yesterday'].push(item);
            }
            else if (dayDiff > 1 && dayDiff <= 7) {
                categories['Previous 7 Days'].push(item);
            }
            else if (dayDiff > 7 && dayDiff <= 30) {
                categories['Previous 30 Days'].push(item);
            }
            else {
                const year = itemMoment.format('YYYY');
                if (!categories[year]) {
                    categories[year] = [];
                }
                categories[year].push(item);
            }
        });

        return categories;
    };

    const renderCategoryItems = (categoryItems) => {
        return categoryItems.map(item => (
            <NavItem key={item.id} className={`m-0 ${location.pathname === `/c/${item.id}` ? 'active' : ''}`}>
                <NavLink href={`/c/${item.id}`} className="text-black m-0 p-2 text-sm fw-normal">
                    <div className='position-relative overflow-hidden text-nowrap w-100'>
                        {item.title.replace(/<\/?[^>]+(>|$)/g, "")}
                        <div className={`position-absolute top-0 bottom-0 end-0 w-50 ${location.pathname === `/c/${item.id}` ? 'sidebar-gradient-secondary' : 'sidebar-gradient-primary'
                            }`}>&nbsp;</div>
                    </div>
                </NavLink>
            </NavItem>
        ));
    };

    const categorizedItems = getCategorizedItems();

    // Get all year categories
    const yearCategories = Object.keys(categorizedItems)
        .filter(key => !fixedCategories.includes(key))
        .sort((a, b) => Number(b) - Number(a)); // Sort years in descending order

    // Combine fixed categories with sorted year categories
    const orderedCategories = [...fixedCategories, ...yearCategories];

    return (
        <div>
            {orderedCategories.map(category => {
                if (categorizedItems[category]?.length > 0) {
                    return (
                        <div key={category} className='mt-4 text-capitalize'>
                            <h6 className="text-secondary text-tertiary text-xs px-2 mt-2">{category}</h6>
                            <Nav className="mb-md-3" navbar>
                                {renderCategoryItems(categorizedItems[category])}
                            </Nav>
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
};

export default ShowByDate;